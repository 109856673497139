import React, { useState } from 'react';
import './ProductTable.css';
import {Link, useHistory} from "react-router-dom";
import {Modal, Spinner} from 'react-bootstrap'
import {approveDraft} from "../../../apis/products";
import MenuAuthCheck from "../../../components/AuthCheck/MenuAuthCheck";
import {ITEM_DRAFT_CONFIGURE, ITEM_DRAFT_EXECUTE} from "../../../constants/scope";

const ProductTable = props => {
    const {products, type, onApprove, onDelete, isApproveLoading, onCancel, handleItemDraftCheckbox, checkedItemDraft, isAllDraftSelected, isDraftLoading, isBulkActionLoading, handleBulkButtonAction} = props;
    const history = useHistory();
    const [show, setShow] = useState(false);

    const productTableStructure = [
        {
            order: 0,
            title: 'No',
            accessor: 'no',
            class: 'special-column'
        },
        {
            order: 1,
            title: 'Edit',
            accessor: 'edit',
            content: cb => <span style={{'color': 'green'}} onClick={() => cb()}>
                <i className="icon-pencil fa-xl"/>
            </span>,
            class: 'special-column'
        },
        {
            order: 2,
            title: 'Tanggal',
            accessor: 'createdAt'
        },
        {
            order: 3,
            title: 'Kode Item',
            accessor: 'skuId'
        },
        {
            order: 4,
            title: 'Universal Barcodes',
            accessor: 'universalBarcodes',
            content: universalBarcodes => universalBarcodes.length === 0 ? '-' : <ul>
                {
                    universalBarcodes.map(ub => <li>{`${ub.serialNumber},${ub.type},${ub.quantityMultiplier}`}</li>)
                }
            </ul>
        },
        {
            order: 4,
            title: 'Status',
            accessor: 'status'
        },
        {
            order: 5,
            title: 'Nama',
            accessor: 'itemName',
        },
        {
            order: 5,
            title: 'UoM',
            accessor: 'sellingUnit'
        },
        {
            order: 6,
            title: 'Kategori',
            accessor: 'categories',
        },
        {
            order: 7,
            title: 'State',
            accessor: 'state'
        },
        {
            order: 8,
            title: 'Warehouse Tag',
            accessor: 'warehouseTag'
        },
        {
            order: 9,
            title: 'Product Tag',
            accessor: 'productTag'
        },
        {
            order: 10,
            title: 'Label',
            accessor: 'label'
        },
        {
            order: 11,
            title: 'Catatan Item',
            accessor: 'notes'
        },
        {
            order: 12,
            title: 'Deskripsi Item',
            accessor: 'description'
        },
        {
            order: 13,
            title: 'Relasi Item',
            accessor: 'relation',
            content: (tct, cb) => tct === 0 ? '0' :
                <a onClick={cb} style={{'color': 'blue', 'textDecoration': 'underline'}}>{
                    tct
                }</a>,
            class: 'special-column'
        },
        {
            order: 14,
            title: 'Dimensi Item',
            accessor: 'dimension'
        },
        {
            order: 15,
            title: 'Berat Item',
            accessor: 'weight'
        },
        {
            order: 16,
            title: 'Catatan Internal',
            accessor: 'internalNotes'
        },
        {
            order: 17,
            title: 'APGP Aktif',
            accessor: 'totalApgp',
            content: (tapgp, cb) => tapgp === 0 ? '0' :
                <a onClick={cb} style={{'color': 'blue', 'textDecoration': 'underline'}}>{
                    tapgp
                }</a>,
            class: 'special-column'
        },
        {
            order: 18,
            title: 'POL',
            accessor: 'pol',
            content: (pol, cb) => pol === 0 ? '0' :
                <a onClick={cb} style={{'color': 'blue', 'textDecoration': 'underline'}}>{
                    pol 
                }</a>,
            class: 'special-column'
        }
    ]
    
    const draftTableStructure = [
        {
            title: <input type={'checkbox'} id={'global-checkbox'} onClick={()=>{handleItemDraftCheckbox("all")}}
                          disabled={products.length === 0} checked={isAllDraftSelected}></input>,
            accessor: 'checkbox'
        },
        {
            title: 'No',
            accessor: 'no',
            class: 'special-column'
        },
        {
            title: 'Dibuat Oleh',
            accessor: 'draftCreatedBy'
        },
        {
            title: 'Created At',
            accessor: 'createdAtWithHour'
        },
        {
            title: 'SKU ID',
            accessor: 'skuId'
        },
        {
            title: 'Nama',
            accessor: 'itemName',
        },
        {
            title: 'UoM',
            accessor: 'sellingUnit'
        },
        {
            title: 'Universal Barcodes',
            accessor: 'universalBarcodes',
            content: universalBarcodes => !universalBarcodes || universalBarcodes.length === 0 ? '-' : <ul>
                {
                    universalBarcodes?.map(ub => <li>{`${ub.serialNumber},${ub.type},${ub.quantityMultiplier}`}</li>)
                }
            </ul>
        },
        {
            title: 'Whitelisted Planograms',
            accessor: 'whitelistedPlanograms',
        },
        {
            title: 'Kategori',
            accessor: 'categories',
        },
        {
            title: 'State',
            accessor: 'state'
        },
        {
            title: 'Warehouse Tag',
            accessor: 'warehouseTag'
        },
        {
            title: 'Product Tag',
            accessor: 'productTag'
        },
        {
            title: 'Label',
            accessor: 'label'
        },
        {
            title: 'BBD Type',
            accessor: 'bestBeforeDateType'
        },
        {
            title: 'Shelf Life',
            accessor: 'shelfLife'
        },
        {
            title: 'Jenis Tempat Penyimpanan',
            accessor: 'storageRoomType'
        },
        {
            title: 'Internal Notes',
            accessor: "internalNotes"
        },
        {
            title: 'Di-Approve Oleh',
            accessor: 'draftApprovedBy'
        },
        {
            title: 'Approved At',
            accessor: 'approvedAtWithHour'
        },
        {
            title: 'Cancelled By',
            accessor: 'deletedBy'
        },
        {
            title: 'Cancelled At',
            accessor: 'deletedAtWithHour'
        },
        {
            order: 11,
            title: 'Aksi',
            accessor: 'edit',
            content: (editFunction, itemData, deleteFunction) => {
                return (
                    <div style={{'whiteSpace': 'nowrap'}} >
                        <button
                            class={"btn btn-warning"} style={{'color': 'white', marginRight: '4px'}} onClick={() => editFunction()}
                            disabled={isApproveLoading || itemData.approvedAt || itemData.deletedAt}
                        >
                            <i className="icon-pencil fa-xl"/>
                        </button>
                        <MenuAuthCheck
                            allowedScopes={[ITEM_DRAFT_EXECUTE]}
                        >
                            <button className="btn btn-success" style={{'color': 'white'}}
                                    onClick={() => onApprove(itemData.id)}
                                    disabled={isApproveLoading || itemData.approvedAt || itemData.deletedAt}>
                                {itemData?.approvedAt ? <i className="icon-check fa-xl"/> : <i className="icon-forward fa-lg"/>}
                            </button>
                        </MenuAuthCheck>
                        <button className="btn btn-danger" style={{'color': 'white', marginLeft: '4px', marginRight: '4px'}}
                                onClick={() => onCancel(itemData.id)}
                                    disabled={isApproveLoading || itemData.approvedAt || itemData.deletedAt}>
                            <i className="fa-solid icon-trash"></i>
                        </button>
                    </div>
                )
            },
        },
    ]

    const redirect = (url, payload) => {
        history.push(url, payload);
    }

    const redirectNewTab = (url, payload) => {
        localStorage.setItem(url, JSON.stringify(payload))
        window.open(url, "_blank")
    }

    const redirectWithQueryParams = (url, payload) => {
        window.open(`${url}?skuId=${payload.skuId}&label=${payload.label}`, "_blank");
    }

    const tableStructure = type === 'DRAFT' ? draftTableStructure : productTableStructure;
    const mapRow = p => {
        const safeGetField = (p, key) => {
            if (p[key] === '' || p[key] === undefined || p[key] === null) return '-';
            return p[key];
        }

        const buildRedirectionColumn = (ts, val, redirect) => <td className={`${ts.class ? ts.class : ''}`}>{
            ts.content(val, redirect)
        }</td>

        const buildCheckboxColumn = (id, product)=>{
            return (
                <td>
                    <input type={'checkbox'} id={`checkbox-${id}`}
                           onClick={()=>{handleItemDraftCheckbox(id)}} checked={checkedItemDraft.get(id)}
                        disabled={product.approvedAt || product.deletedAt}
                    ></input>
                </td>
            );
        }

        const {skuId, itemName, id, itemDraftId, universalBarcodes, totalApgp, totalConversionTemplates, pol} = p;

        return <tr>
            {
                tableStructure.sort((a, b) => a.order - b.order).map(ts => {
                        if (ts.accessor === 'edit' && type !== 'DRAFT') {
                            return <td className={`${ts.class ? ts.class : ''}`}>{
                                ts.content(
                                    () => {
                                        redirect("/items/create-or-update",
                                            {
                                                label: `${skuId} - ${itemName}`,
                                                skuId: skuId,
                                                itemDraftId: itemDraftId,
                                                type
                                            }
                                        )
                                    },
                                    p,
                                    () => {
                                        onDelete(id);  
                                    }
                                )
                            }</td>
                        } else if (ts.accessor === 'edit' && type === 'DRAFT') {
                            return <td className={`${ts.class ? ts.class : ''}`}>{
                                ts.content(
                                    () => {
                                        redirectWithQueryParams(`/item-draft/configure`,
                                            {
                                                label: `${skuId} - ${itemName}`,
                                                skuId: skuId,
                                                itemDraftId: itemDraftId,
                                                type
                                            }
                                        )
                                    },
                                    p,
                                    () => {
                                        onDelete(id);
                                    }
                                )
                            }</td>
                        }
                        if (ts.accessor === 'universalBarcodes') {
                            return <td className={`${ts.class ? ts.class : ''}`}>{
                                ts.content(universalBarcodes)
                            }</td>
                        }
                        if (ts.accessor === 'totalApgp') {
                            return buildRedirectionColumn(ts, totalApgp,
                                () => redirectNewTab("/price-group-products/configure", {skuId: skuId, id: id}));
                        }
                        if (ts.accessor === 'relation') {
                            return buildRedirectionColumn(ts, totalConversionTemplates,
                                () => redirect("/conversion-template", {skuId: skuId}));
                        }
                        if (ts.accessor === 'pol') {
                            return buildRedirectionColumn(ts, pol,
                                () => redirectNewTab("/products/order-limits", {productId: id}));
                        }
                        if(ts.accessor === 'checkbox'){
                            return buildCheckboxColumn(p.id, p);
                        }
                        return <td className={`${ts.class ? ts.class : ''}`}>{
                            safeGetField(p, ts.accessor)
                        }</td>
                    }
                )
            }
        </tr>
    }

    return <>
        {type === 'DRAFT'?
            <div className={"w-100 d-flex justify-content-end"}>
                <div className={'col-2'}>
                    <button className='btn bulk-action-button approve-button' disabled={checkedItemDraft.size === 0 || isBulkActionLoading} onClick={()=>{handleBulkButtonAction("APPROVE")}}>
                        {isBulkActionLoading && <Spinner animation="border" variant="dark" size="sm"/>}
                        Approve ({checkedItemDraft.size})
                    </button>
                </div>
                <div className={'col-2'}>
                    <button className='btn bulk-action-button cancel-button' disabled={checkedItemDraft.size === 0 || isBulkActionLoading} onClick={()=>{handleBulkButtonAction("CANCEL")}}>
                        {isBulkActionLoading && <Spinner animation="border" variant="dark" size="sm"/>}
                        Cancel ({checkedItemDraft.size})
                    </button>
                </div>
            </div>
            : <></>
        }
        <div className='product-table product-table-container'>
            <table className='ml-2'>
                <thead>
                <tr>
                    {
                        tableStructure.sort((a, b) => a.order - b.order).map(ts => <th>{ts.title}</th>)
                    }
                </tr>
                </thead>
                <tbody>
                {
                    products.map(p => mapRow(p))
                }
                </tbody>
            </table>
        </div>
    </>
}

export default ProductTable;