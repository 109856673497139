import React from 'react';
import { BsBox } from "react-icons/bs";

const ACTIVE_LINKS = {
    CATEGORIES: "/categories/categories",
}

const LINKS_WITH_SUBHEADERS = [
    {
        label: "",
        links: [{ icon: <BsBox />, link: ACTIVE_LINKS.CATEGORIES, label: 'Categories' }]
    },
]

export { ACTIVE_LINKS, LINKS_WITH_SUBHEADERS };