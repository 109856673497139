import React from "react";
import { Spinner } from "react-bootstrap";

import clsx from "clsx";

import styles from "./ButtonV2.module.css";

const ButtonV2 = ({
    text,
    variant = "outline",
    onClick,
    isDisabled,
    type,
    customStyling,
    isSpinnerShown,
    style,
    paddingX,
}) => {
    return (
        <button
            className={clsx("btn", customStyling, {
                [styles.buttonGreen]: variant === "fill",
                [styles.buttonWhite]: variant === "outline",
                "px-4": !paddingX,
                [`px-${paddingX}`]: paddingX,
            })}
            type={type || "button"}
            disabled={isDisabled}
            onClick={onClick}
            style={style}
        >
            {isSpinnerShown && (
                <Spinner
                    animation="border"
                    variant={variant === "fill" ? "light" : "dark"}
                    size="sm"
                />
            )}{" "}
            {text}
        </button>
    );
};

export default ButtonV2;
