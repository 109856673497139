import React, { useEffect, useState } from "react";
import WarehouseTransferNavigationSidebar from "../warehouse_transfer_dashboard_page/warehouse_transfer_nvaigation_sidebar/WarehouseTransferNavigationSidebar";
import { bindActionCreators } from "redux";
import { displayAlert } from "../../../redux/actions/notif";
import { connect } from "react-redux";
import { ACTIVE_LINKS, LINKS_WITH_SUBHEADERS } from "./navigation-link";
import PageAuthCheck from "../../../components/AuthCheck/PageAuthCheck";
import PageWithSideNavbar from "../../../components/PageWithSideNavbar/PageWithSideNavbar";
import Categories from "./Categories";
import "./style.css";
import { WAREHOUSE_TRANSFER_CATEGORY_VIEW, WAREHOUSE_TRANSFER_CATEGORY_CREATE, WAREHOUSE_TRANSFER_CATEGORY_ASSIGN } from "../../../constants/scope";

const WarehouseTransferDashboard = (props) => {
    const { displayAlert, user } = props;
    const [activeLink, setActiveLink] = useState(props.location?.pathname);
    useEffect(() => {
        const initializePage = async () => {
        }
        initializePage();
    }, []);

    const changePage = (link, title) => {
        window.history.replaceState(null, title, link);
        setActiveLink(link);
    }

    const navBar = <WarehouseTransferNavigationSidebar
        activeLink={activeLink}
        linksWithSubheaders={LINKS_WITH_SUBHEADERS}
        onClick={changePage}
        hideCollapseToggle={true}
    />

    const manageContent = () => {
        let content = <></>;
        if (activeLink === ACTIVE_LINKS.CATEGORIES) {
            content = <Categories displayAlert={displayAlert} user={user} />;
        }
        return content;
    }

    return (
        <PageAuthCheck
            showUser={false}
            allowedScopes={[WAREHOUSE_TRANSFER_CATEGORY_VIEW, WAREHOUSE_TRANSFER_CATEGORY_CREATE, WAREHOUSE_TRANSFER_CATEGORY_ASSIGN]}
        >
            <PageWithSideNavbar isHideOverflowX={true} navbar={navBar} content={manageContent()}/>
        </PageAuthCheck>
    );
}

const mapStateToProps = (state) => ({ user: state.auth.user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseTransferDashboard);