import Spinner from "react-bootstrap/Spinner";
import React from "react";

function TableBodyResponse(props) {
    if (props.isSubmitting) {
        return <Spinner animation="border" size="lg" />;
    }

    if (!props.titleRow && !props.contentRows) {
        return <div></div>;
    }

    let titleRow = "";
    if (props.titleRow) {
        let titles = [];
        props.titleRow.forEach((title, index) => {
            titles.push(<th key={"title" + index}>{title}</th>);
        });
        titleRow = <tr>{titles}</tr>;
    }

    let contents = [];
    if (props.contentRows) {
        props.contentRows.forEach((contentRow) => {
            let contentRowArr = [];
            contentRow.forEach((content, index) => {
                contentRowArr.push(
                    <td>{content}</td>
                );
            });
            contents.push(
                <tr>{contentRowArr}</tr>
            );
        });
    }
    return <div className="body-div">
        <table className="res-table">
            <thead>
                {titleRow}
            </thead>
            <tbody>
                {contents}
            </tbody>
        </table>
    </div>;
}

export default TableBodyResponse;