import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from "react-bootstrap";
import styles from "./style.module.css";
import "./style.module.css"
import CategoryAdjustment from './AdjustmentPage';
import { displayAlert } from '../../../../redux/actions/notif';
import * as apiProductTag from "../../../../apis/productTags";
import * as warehouseTagGroupApi from "../../../../apis/warehouseTagGroup.js";

const _ = require('lodash');
const Categories = (props) => {
    const [warehouseTagGroupData, setWarehouseTagGroupData] = useState([]);
    const [warehouseTagOptions, setWarehouseTagOptions] = useState([]);
    const [warehouseGroupFunctionalityEnumOptions, setWarehouseGroupFunctionalityEnumOptions] = useState([]);

    useEffect(async () => {
        setWarehouseTagGroupData([]);
        if (warehouseTagGroupData.length === 0) {
            fetchWarehouseGroupTag();
        }
        if (warehouseTagOptions.length === 0) {
            let productTagResponse = await apiProductTag.getAllWithWhPrefix();
            let options = productTagResponse?.data?.data?.map(productTag => {
                return {
                    value: productTag.id,
                    label: productTag.name
                }
            });
            setWarehouseTagOptions(options);
        }

        if (warehouseGroupFunctionalityEnumOptions.length === 0) {
            warehouseTagGroupApi.getAllWarehouseGroupFunctionalityEnumTypes().then((result) => {
                setWarehouseGroupFunctionalityEnumOptions(result?.data?.data);
            });
        }
    }, []);

    const Title = () => {
        return (
            <div className={styles.title}>Categories</div>
        );
    }

    const fetchWarehouseGroupTag = async () => {
        try {
            warehouseTagGroupApi.getAll().then((result) => {
                setWarehouseTagGroupData(result?.data?.data)
            }).catch(err => {
                throw err;
            });
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: "error" });
        }
    }

    return (
        <div className={"w-100 h-100 overflow-auto"}>
            <Title />
            <Tabs
                className={styles.tabTitle}
                defaultActiveKey="pengaturan"
                selectedTabClassName={styles.activeTab}
            >
                <Tab eventKey="pengaturan" title="PENGATURAN">
                    <CategoryAdjustment
                        warehouseTagGroupData={warehouseTagGroupData}
                        displayAlert={displayAlert}
                        warehouseTagOptions={warehouseTagOptions}
                        functionalityEnumOptions={warehouseGroupFunctionalityEnumOptions ?? []}
                        fetchWarehouseGroupTag={fetchWarehouseGroupTag}
                        user={props.user}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}


export default Categories;