export const ADMIN = "admin";
export const COFOUNDERS = "clevel";
export const WAREHOUSE_SPV = "warehouse_spv";
export const WAREHOUSE_WORKER = "warehouse_worker";
export const OPS_LOGISTIC_DRIVER = "ops_logistic_driver";
export const OPS_LOGISTIC_CAPTAIN_DRIVER = "ops_logistic_captain_driver";
export const OPS_LOGISTIC_DISPATCHER = "Ops_logistic_dispatcher";
export const VENDOR_IDX_DELIVERY = "vendor_idx_delivery";
export const VENDOR_RARA_DELIVERY = "vendor_rara_delivery";
export const SAME_DAY_ADMIN = "same_day_admin";
export const SAME_DAY_COURIER = "same_day_courier";
export const NEXT_DAY_COURIER = "next_day_courier";
export const OPS_WH_MANAGER = "ops_wh_manager";
export const OPS_SS_S = "ops_ss_s";
export const OPS_LOGISTIC_ROUTER = "ops_logistic_router";
export const GENERAL_ACCESS = 'general_access';
export const C_LEVEL = 'clevel';
export const BD_SALES_ANALYTICS = 'bd_sales_analytics';
export const OPS_ANALYTICS = 'ops_analytics';
export const OPS_HEAD = 'ops_head';
export const LOGISTICS_LEAD = 'logistics_lead';
export const SDD_TRIAL_RIDER = 'sdd_trial_rider';
export const SDD_AUTO_ASSIGN_RIDER = 'sdd_auto_assign_rider';
export const PPIC_INVENTORY_CONTROL = "ppic_inventory_control";

export const ITEM_MASTER_CORE	= "item_master_core";
export const ITEM_MASTER_CATEGORY	= "item_master_category";
export const ITEM_MASTER_MARKETING	= "item_master_marketing";
export const ITEM_MASTER_DOWNSTREAM	= "item_master_downstream";
export const ITEM_MASTER_FSQ	= "item_master_fsq";
export const ITEM_MASTER_ACCOUNTING= "item_master_accounting";
export const OPS_IMAGE_STAFF = "ops_image_staff"
export const EXTERNAL_CHANNEL_STAFF = "external_channel_staff";